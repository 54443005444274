<template>
  <b-card>
    <b-card-header>
      <b-card-title class="text-primary">
        <h4>{{ $t('IMPORT_COMPANY_REPORT_TITLE') }}</h4>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="LayersIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ report.try }} {{ $t('IMPORT_COMPANY_LINE_IN_FILE') }}
              </h4>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="CheckCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ report.success }} {{ $t('IMPORT_COMPANY_LINE_IMPORTED') }}
              </h4>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="XCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ report.fail }} {{ $t('IMPORT_COMPANY_LINE_ERROR') }} ({{ $t('IMPORT_COMPANY_LINE_ALREADY_EXIST') }} {{ report.existing }})
              </h4>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMedia, BMediaBody, BMediaAside, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
  },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
  },
  methods: {

  },
}
</script>
