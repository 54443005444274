<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card-header>
            <b-card-title class="text-primary">
              <h4>{{ $t('IMPORT_COMPANY_REPORT_TITLE') }}</h4>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-file
                  v-model="file"
                  :placeholder="$t('DROP_CSV_FILE')"
                  :drop-placeholder="$t('DROP_CSV_FILE')"
                  accept=".csv"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <b-button
                  v-if="loading"
                  variant="primary"
                  type="button"
                  class="mt-3"
                  disabled
                >
                  <b-spinner small /> Loading ...
                </b-button>
                <b-button
                  v-if="file !== null && !loading"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mt-3"
                  variant="primary"
                  @click="importer"
                >
                  {{ $t('IMPORT_BUTTON') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="displayReport">
      <b-col md="12">
        <report :report="report" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BFormFile, BRow, BCol, BButton, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import report from './importReport.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BFormFile,
    report,
    BButton,
    BRow,
    BCol,
    BCardHeader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      displayReport: false,
      loading: false,
      file: null,
      report: {},
    }
  },
  methods: {
    importer() {
      this.loading = true
      const that = this
      const form = new FormData()
      form.append('file', that.file)

      this.$store.dispatch('companies/importer', form)
        .then(r => {
          that.loading = false
          that.report = r
          that.displayReport = true
          this.$swal({
            title: this.$i18n.t('IMPORT_SUCCESS'),
            icon: 'success',
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.$swal({
            title: this.$i18n.t('IMPORT_ERROR'),
            icon: 'error',
            showConfirmButton: false,
          })
          this.loading = false
        })
    },
  },

}
</script>
